export const m = {
  headTit1: '用户',
  headTit2: '商户',
  headTit3: '节点运营商',
  headTit4: '投资人',
  headTit5: '关于我们',
  contOne1: '用户',
  contOne2: '兑换 ｜ 购买、出售、交换您的数字资产',
  contOne3: '商城 ｜ 用数字资产购买到您想要的任何东西',
  contOne4: '更好的是，每次付款时都会获得新数字货币奖励！',
  contOne5: 'DePay',
  contOne6: '钱包',
  contMerch1: '​商户',
  contMerch2:
    '越来越多的客户，尤其是国际客户，打算使用数字钱包进行支付。如果您想轻松地接受这些付款，那么 MatePay 商家解决方案就是您的最佳选择。',
  contMerch3: '加入我们，请发邮件给我们：',
  contMerch4: ' support@depay.biz ',
  contMerch5: '比传统的国际支付便宜得多',
  contMerch6: '通过区块链，我们与三方合作形成紧密的反馈，使交易分布在区块链中，减少了传统支付过程中繁琐的结算费用。',
  contMerch7: '近乎实时地获取资金',
  contMerch8:
    '基于区块链的特性，我们的系统对交易进行监控，并在发起时锁定价值锚点，以便通过后续匹配将整个交易匹配成一个订单，同时即时反馈用户可以去现金。',
  contMerch9: '通过数字化简化您和您客户的生活',
  contMerch10:
    '随时随地查看您的交易状态，数据订单历史可以帮助您跟踪您的交易，24小时客服随时为您解答问题，让您安心交易。我们站在客户的角度解决您的疑惑和操作使用问题，不断改进我们的服务。',
  contOpera1: '节点运营商',
  contOpera2:
    '如果您是支付处理方或收单方，除了处理传统的支付交易外，您还对数字货币支付技术感兴趣；您可以成为 DePay DAO 社区的节点运营商，成为合作伙伴。这样就可以实现一店二鸟。从传统支付中获利，并为您生成的每笔交易获得数字代币奖励。',
  contInves1: '投资人',
  contInves2: '我们的天使投资人：Intrinsic Capital LLC，。',
  contInves3: '如果您对由人民和人民建立的分布式支付技术有相同的愿景，并希望加入 DePay DAO 社区。请告诉我们。',
  contInves4: '对于白皮书：',
  contInves5: ' support@depay.biz',
  contAbout1: '关于我们',
  contAbout2: 'DePay 是基于分布式技术的下一代支付网络。它由商家和节点运营商的支付DAO社区构建和运营。',
  fourerBot1: '加入我们的邮件列表，绝不会错过任何更新！',
  fourerBot2: '电子邮件地址*',
  fourerBot3: '订阅',
  fourerBot4: '© 2022 by Metapay Technology LLC',
  selePop1: 'Top of Page',
  selePop2: '​商户',
  selePop3: '节点运营商',
  selePop4: '投资人',
  selePop5: '关于我们'
}
