<template>
  <div class="home">
    <el-container>
      <seleHead
        @goView="goView"
        class="home_head_box"
        :headIndexdef="headIndexdef"
      ></seleHead>
      <el-main style="marign:0;padding:0;">
        <homeCont
          ref="child"
          :headIndexdefAnm="headIndexdefAnm"
        ></homeCont>
      </el-main>
      <el-footer style="marign:0;padding:0;">
        <homeFout></homeFout>
      </el-footer>
    </el-container>
    <selePop
      @goView="goView"
      :headIndexdef="headIndexdef"
    ></selePop>
  </div>
</template>

<script>
// @ is an alias to /src
// import homeHead form './component/home_head.vue'
import seleHead from './component/seleHead.vue'
import homeCont from './component/homeCont.vue'
import homeFout from './component/homeFout.vue'
import selePop from './component/selePop.vue'

export default {
  name: 'Home',
  components: {
    seleHead,
    homeCont,
    homeFout,
    selePop
  },
  data() {
    return {
      // 滚动条的高度
      scrollTop: 0,
      oldScrollTop: 0, //记录上一次滚动结束后的滚动距离
      headIndexdef: null,
      headIndexdefAnm: null
    }
  },
  mounted() {
    // document.documentElement.scrollTop = 1
    let mouScrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
    //判断第一次加载 scrolltop第一模块动画
    if (mouScrollTop === 0) {
      this.headIndexdefAnm = 0
    }
    //监听滚动
    window.addEventListener('scroll', this.handleScroll, true)
  },
  destroyed() {
    // 离开该页面需要移除这个监听的事件，不然会报错
    window.removeEventListener('scroll', this.handleScroll)
  },
  watch: {
    scrollTop(newValue, oldValue) {
      setTimeout(() => {
        if (newValue == window.scrollY) { //延时执行后当newValue等于window.scrollY，代表滚动结束
          this.oldScrollTop = newValue
          //每次滚动结束后都要给oldScrollTop赋值
          this.scrollShowView()
        }
      }, 500)
      //必须使用延时器，否则每次newValue和window.scrollY都相等，无法判断，20ms刚好大于watch的侦听周期，故延时20ms
      if (this.oldScrollTop == oldValue) { //每次滚动开始时oldScrollTop与oldValue相等
        console.log('滚动开始')
      }
    }
  },
  methods: {
    // 保存滚动值，这是兼容的写法
    handleScroll() {
      this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      //动画显示一次
      if (this.scrollTop < 600) {
        //user
        this.headIndexdefAnm = 0
      } else if (this.scrollTop > 600 && this.scrollTop < 2500) {
        this.headIndexdefAnm = 1
        //merchant
      } else if (this.scrollTop > 2500 && this.scrollTop < 3320) {
        this.headIndexdefAnm = 2
        //nodeOperator
      } else if (this.scrollTop > 3320 && this.scrollTop < 4200) {
        this.headIndexdefAnm = 3
        //investor
      } else if (this.scrollTop > 4200) {
        this.headIndexdefAnm = 4
        //aboutUs
      }
    },
    //显示位置模块
    scrollShowView() {
      if (this.scrollTop < 840) {
        //user
        this.headIndexdef = 0
      } else if (this.scrollTop > 840 && this.scrollTop < 2800) {
        this.headIndexdef = 1
        //merchant
      } else if (this.scrollTop > 2800 && this.scrollTop < 3620) {
        this.headIndexdef = 2
        //nodeOperator
      } else if (this.scrollTop > 3620 && this.scrollTop < 4200) {
        this.headIndexdef = 3
        //investor
      } else if (this.scrollTop > 4200) {
        this.headIndexdef = 4
        //aboutUs
      }
    },
    /* 跳转view */
    goView(e) {
      this.$refs.child.goScrollView(e)
    }
  }
}
</script>

<style lang="less" scoped>
.home {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
.home_head_box {
  position: fixed;
  top: 0;
  width: 100%;
}
</style>