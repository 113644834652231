<template>
  <div class="homeFout_box">
    <!-- <div class="box_case">
      <span class="fout_tit">{{$t('m.fourerBot1')}}</span>
      <div>
        <el-input
          v-model="fouter_input"
          :placeholder="$t('m.fourerBot2')"
          style="width:360px;"
        ></el-input>
        <el-button
          type="warning"
          class="fout_Btn"
        >{{$t('m.fourerBot3')}}</el-button>
      </div>
    </div> -->
    <div class="iconFoutList">
      <a
        class="aMargBox"
        href="https://www.depay.biz/"
        target="_blank"
      >
      <div title='DePay' class="icon-logo"></div>
      </a>
      <a
        class="aMargBox"
        href="https://twitter.com/MetaPay_"
        target="_blank"
      >
        <svg
          width="24px"
          height="24px"
          viewBox="0 0 24 24"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <title>twitter</title>
          <g
            id="页面-1"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g
              id="tuitetwitter43-2"
              fill="#FFFFFF"
              fill-rule="nonzero"
            >
              <path
                d="M24,12 C24,5.37272728 18.6272727,0 12,0 C5.37272728,0 0,5.37272728 0,12 C0,18.6272727 5.37272728,24 12,24 C18.6272727,24 24,18.6272727 24,12 Z M5.53854546,16.2643636 C5.74581818,16.2905455 5.95745456,16.3047273 6.17127274,16.3047273 C7.4138182,16.3069091 8.55709092,15.8585455 9.46363638,15.1058182 C8.3029091,15.0785455 7.32436366,14.2581818 6.98727274,13.1323636 C7.14872728,13.1661818 7.31563638,13.1858182 7.4858182,13.1869091 C7.72800002,13.188 7.96145456,13.1552727 8.18400002,13.092 C6.97090913,12.8236364 6.0567273,11.6727273 6.0567273,10.3014545 C6.0567273,10.2894545 6.0567273,10.2774545 6.0567273,10.2654545 C6.41454548,10.4814545 6.82254548,10.6123636 7.2578182,10.632 C6.54763636,10.1181818 6.07963636,9.24545454 6.07963636,8.26363636 C6.07963636,7.74436364 6.21054546,7.26 6.43854546,6.84545454 C7.74654546,8.57454544 9.70036364,9.72218182 11.9029091,9.8650909 C11.8570909,9.65890908 11.8341818,9.444 11.8341818,9.22472726 C11.8341818,7.67454544 13.0210909,6.43745454 14.4850909,6.46145454 C15.2476364,6.47454544 15.9370909,6.82472726 16.4203636,7.37454544 C17.0247273,7.25890908 17.592,7.03636362 18.1036364,6.72545454 C17.9061818,7.37236364 17.4850909,7.91127272 16.9374545,8.24836364 C17.4741818,8.18836364 17.9847273,8.04654546 18.4603636,7.83163636 C18.1047273,8.38472726 17.6552727,8.868 17.1370909,9.25418182 C17.1425455,9.37418182 17.1447273,9.49418182 17.1447273,9.61418182 C17.1447273,13.2949091 14.4774546,17.5396364 9.60109092,17.5374564 C8.10545454,17.5385455 6.71127272,17.0694545 5.53854546,16.2643636 Z"
                id="形状"
              ></path>
            </g>
          </g>
        </svg>
      </a>
      <a
        class="aMargBox"
        href="https://t.me/DePay_Global"
        target="_blank"
      >
        <svg
          width="24px"
          height="24px"
          viewBox="0 0 24 24"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <title>telegram</title>
          <g
            id="页面-1"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g
              id="telegram-4"
              fill="#FFFFFF"
              fill-rule="nonzero"
            >
              <path
                d="M15.924,17.5045714 L17.8928571,8.22342858 C18.0668571,7.40657145 17.598,7.08514287 17.0622857,7.28571429 L5.49085713,11.7454286 C4.70057142,12.0531429 4.71428571,12.4954286 5.35714287,12.696 L8.31685713,13.62 L15.1877143,9.294 C15.5091429,9.07971429 15.804,9.20057142 15.5631429,9.41485713 L10.0054286,14.4368571 L9.79114287,17.4908571 C10.0988572,17.4908571 10.2334286,17.3571428 10.3937143,17.196 L11.8397143,15.8031429 L14.8397143,18.0128571 C15.3891429,18.3205714 15.7774286,18.1602857 15.9248571,17.5037143 L15.924,17.5045714 Z M24,12 C24,18.6291429 18.6291429,24 12,24 C5.37085713,24 0,18.6291429 0,12 C0,5.37085713 5.37085713,0 12,0 C18.6291429,0 24,5.37085713 24,12 Z"
                id="形状"
              ></path>
            </g>
          </g>
        </svg>
      </a>
      <a
        class="aMargBox"
        href="https://discord.gg/x8YwDUEUrC"
        target="_blank"
      >
        <svg
          width="24px"
          height="24px"
          viewBox="0 0 24 24"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <title>Discord</title>
          <g
            id="页面-1"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <path
              d="M12,0 C18.627417,0 24,5.372583 24,12 C24,18.627417 18.627417,24 12,24 C5.372583,24 0,18.627417 0,12 C0,5.372583 5.372583,0 12,0 Z M13.9000078,6.57 L13.7299922,6.73999219 L13.7391813,6.74274256 C13.8786554,6.7848372 15.5729092,7.30834082 16.56,8.19 C16.56,8.19 14.6800078,7.03999219 12,7.03999219 L12,7.03999219 L11.7256559,7.04400017 C9.28304023,7.11529509 7.56617933,8.1146971 7.44011519,8.189 L7.445,8.185 L7.57257544,8.07704223 C8.57147814,7.2637288 10.1274087,6.783007 10.2608187,6.74274256 L10.2700078,6.73999219 L10.0999922,6.57 L9.89804967,6.57892416 C8.29005581,6.691728 6.88000781,7.77 6.88000781,7.77 C5.41800117,10.8214934 5.27816953,13.518973 5.26911519,14.2595583 L5.26911519,14.2595583 L5.26999219,14.46 L5.40750762,14.6291753 C6.80203596,16.2617555 8.74999219,16.14 8.74999219,16.14 L8.74999219,16.14 L9.46000781,15.24 L9.27599695,15.1934437 C8.13296871,14.861775 7.42000781,13.86 7.42000781,13.86 C7.42000781,13.86 9.3,15.1399922 12,15.1399922 C14.52,15.1399922 16.3256821,14.0249768 16.5552397,13.876308 L16.5552397,13.876308 L16.5799922,13.86 L16.5741085,13.8680812 C16.4998437,13.9689 15.727507,14.9835 14.5399922,15.24 L14.5399922,15.24 L15.2500078,16.14 C15.383493,16.1463628 17.3751597,16.2114343 18.7301837,14.4542283 L18.7301837,14.4542283 L18.7310455,14.4087885 L18.7310455,14.4087885 L18.7313295,14.3099055 C18.7278286,13.6494391 18.6174108,10.895405 17.1199922,7.77 C17.1199922,7.77 15.5899922,6.6 13.9000078,6.57 L13.9000078,6.57 Z M9.93,10.83 C10.5799922,10.83 11.1100078,11.4 11.1,12.1000078 C11.1,12.7900078 10.5799922,13.3699922 9.93,13.3699922 C9.28999219,13.3699922 8.77000781,12.7900078 8.77000781,12.1000078 C8.77000781,11.4 9.28000781,10.83 9.93,10.83 Z M14.1,10.83 C14.7499922,10.83 15.27,11.4 15.27,12.1000078 C15.27,12.7900078 14.7499922,13.3699922 14.1,13.3699922 C13.4599922,13.3699922 12.9400078,12.7900078 12.9400078,12.1000078 C12.9400078,11.4 13.4500078,10.83 14.1,10.83 Z"
              id="形状结合"
              fill="#FFFFFF"
              fill-rule="nonzero"
            ></path>
          </g>
        </svg>

      </a>

    </div>
    <span class="fouter_TitMess">{{$t('m.fourerBot4')}}</span>
  </div>
</template>

<script>
export default {
  name: '',
  data() {
    return {
      fouter_input: ''
    }
  },
  created() {

  },
  mounted() {

  },
  methods: {

  },
}
</script>

<style scoped lang='less'>
.homeFout_box {
  // height: 340px;
  height: 200px;
  width: 100%;
  background-color: rgb(0, 0, 0);
  padding-top: 69px;
  box-sizing: border-box;
  .box_case {
    width: 980px;
    margin: 0 auto 40px;
    display: flex;
    flex-direction: column;
    .fout_tit {
      color: rgb(255, 255, 255);
      margin: 12px 0 15px;
    }
    .fout_Btn {
      background-color: rgb(197, 173, 139);
      margin-left: 10px;
    }
  }
  .iconFoutList {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 28px;
    .aMargBox {
      margin-right: 16px;
      img {
        width: 24px;
        height: 24px;
      }
    }
    .icon-logo{
      width: 24px;
        height: 24px;
        background-image: url('../imgs/icon-logo.svg');
        background-repeat: no-repeat;
        background-size: 24px 24px;
      }
  }
  .fouter_TitMess {
    font-size: 13px;
    font-weight: normal;
    color: rgb(153, 153, 151);
  }
}
</style>
