<template>
  <div class="selePopHome">
    <ul>
      <li>
        <span v-show="(headIndex === 0 && this.seen) || (headIndexMouse === 0 && this.seenMouse)">{{$t('m.selePop1')}}</span>
        <div
          :class="(headIndex === 0 && this.seen) || (headIndexMouse === 0 && this.seenMouse) ? 'popHomeRou popHomeRouCli' : 'popHomeRou'"
          @mouseenter="enter(0)"
          @mouseleave="leave"
          @click="toView('user',0)"
        ></div>
      </li>
      <li>
        <span v-show="(headIndex === 1 && this.seen) || (headIndexMouse === 1 && this.seenMouse)">{{$t('m.selePop2')}}</span>
        <div
          :class="(headIndex === 1 && this.seen) || (headIndexMouse === 1 && this.seenMouse) ? 'popHomeRou popHomeRouCli' : 'popHomeRou'"
          @mouseenter="enter(1)"
          @mouseleave="leave"
          @click="toView('merchant',1)"
        ></div>
      </li>
      <li>
        <span v-show="(headIndex === 2 && this.seen) || (headIndexMouse === 2 && this.seenMouse)">{{$t('m.selePop3')}}</span>
        <div
          :class="(headIndex === 2 && this.seen) || (headIndexMouse === 2 && this.seenMouse) ? 'popHomeRou popHomeRouCli' : 'popHomeRou'"
          @mouseenter="enter(2)"
          @mouseleave="leave"
          @click="toView('nodeOperator',2)"
        ></div>
      </li>
      <li>
        <span v-show="(headIndex === 3 && this.seen) || (headIndexMouse === 3 && this.seenMouse)">{{$t('m.selePop4')}}</span>
        <div
          :class="(headIndex === 3 && this.seen) || (headIndexMouse === 3 && this.seenMouse) ? 'popHomeRou popHomeRouCli' : 'popHomeRou'"
          @mouseenter="enter(3)"
          @mouseleave="leave"
          @click="toView('investor',3)"
        ></div>
      </li>
      <li>
        <span v-show="(headIndex === 4 && this.seen) || (headIndexMouse === 4 && this.seenMouse)">{{$t('m.selePop5')}}</span>
        <div
          :class="(headIndex === 4 && this.seen) || (headIndexMouse === 4 && this.seenMouse) ? 'popHomeRou popHomeRouCli' : 'popHomeRou'"
          @mouseenter="enter(4)"
          @mouseleave="leave"
          @click="toView('aboutUs',4)"
        ></div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: '',
  data() {
    return {
      headIndex: null,
      seen: false,
      headIndexMouse: null,
      seenMouse: false
    }
  },
  created() {

  },
  mounted() {

  },
  props: {
    headIndexdef: {
      type: Number,
    }
  },
  watch: {
    headIndexdef: {
      handler(newVal, oldVal) {
        console.log(oldVal)
        this.headIndex = newVal
        this.seen = true
      }
    }
  },
  methods: {
    enter(e) {
      this.seenMouse = true;
      this.headIndexMouse = e
    },
    toView(item, e) {
      this.seen = true;
      this.headIndex = e
      this.seenMouse = false;
      this.headIndexMouse = null
      this.$emit('goView', item)
    },
    leave() {
      this.seenMouse = false;
      this.headIndexMouse = null
    }
  },
}
</script>

<style scoped lang='less'>
.selePopHome {
  width: 300px;
  position: fixed;
  right: 100px;
  top: 30%;
  ul {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-end;
    li {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      margin-bottom: 10px;
      color: rgb(148, 130, 104);
      height: 30px;
      .popHomeRou {
        width: 12px;
        height: 12px;
        cursor: pointer;
        border-radius: 50%;
        background: ffffff;
        margin-left: 10px;
        border: 2px solid rgb(148, 130, 104);
      }
      .popHomeRouCli {
        background: rgb(148, 130, 104) !important;
        transition: background-color 1s;
      }
    }
  }
}
</style>
