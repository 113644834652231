export const m = {
  headTit1: 'User',
  headTit2: 'Merchant',
  headTit3: 'Node Operator',
  headTit4: 'Investor',
  headTit5: 'About Us',
  contOne1: 'For User',
  contOne2: 'Use DePay to buy/sell/exchange digital coins.',
  contOne3: 'And find out where you can buy stuff with your digital coins.',
  contOne4: 'Better yet, get new coins every time you pay.',
  contOne5: 'DePay',
  contOne6: 'Wallet',
  contMerch1: 'Merchant',
  contMerch2:
    'More and more customers, especially international customers, intend to use the digital wallet to make payments. If you want to receive these payments without hassle, DePay Merchant Solutions will be your best option.',
  contMerch3: 'Please join us and send us an email:',
  contMerch4: 'support@depay.biz',
  contMerch5: 'Much cheaper than traditional international payment',
  contMerch6:
    'Through blockchain, we work with three parties to form a tight feedback so that transactions are distributed in the blockchain, reducing the high clearing fee and the cumbersome procedure of the traditional payment process.',
  contMerch7: 'Get your money in near real time',
  contMerch8:
    'Based on the nature of blockchain, our system will monitor the transaction and lock in the value anchor as soon as the transaction is initiated, so that the entire transaction can be made into one order form by subsequent matching process, which at the same time gives immediate feedback to users who can get cash in near real time.',
  contMerch9: "Simplify your life and your clients' by digitalization",
  contMerch10:
    "You can look up the status of an individual transaction anytime and anywhere by tracking historical order data. 24-hour customer service that is always available to answer your questions can set your mind at rest. We will solve your problems in use from the customer's perspective, and constantly improve our service.",
  contOpera1: 'Node Operator',
  contOpera2:
    'If you are a payment processor or an acquirer, who in addition to processing a traditional payment transaction, is also interested in digital currency payment technology; you could be a partner by becoming a node operator in the DePay DAO community. In this way, you can kill two birds with one stone: make profit from traditional payment and also get the digital token award for every transaction you have generated.',
  contInves1: 'Investor',
  contInves2: 'Our Angel Investor: Intrinsic Capital LLC.',
  contInves3:
    'If you share the same vision about the distributed payment technology built by the people and for the people and want to join the DePay DAO community, please let us know.',
  contInves4: 'For white paper: ',
  contInves5: 'support@depay.biz',
  contAbout1: 'About Us',
  contAbout2:
    'DePay is a new-generation payment network based on distributed technology. It is constructed and operated by a payment DAO community of merchants and node operators.',
  fourerBot1: 'Join our mailing list and never miss any update!',
  fourerBot2: 'Email address*',
  fourerBot3: 'SUBSCRIBE',
  fourerBot4: '© 2022 by Metapay Technology LLC',
  selePop1: 'Top of Page',
  selePop2: 'Merchant',
  selePop3: 'Operator',
  selePop4: 'Investor',
  selePop5: 'About Us'
}
