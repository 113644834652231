<template>
  <div class="contBox flexCol">
    <!-- user -->
    <div
      class="flexCol contBoxHeadBack"
      id="user"
    >
      <!-- video -->
      <video
        src="../imgs/file.mp4"
        class="posVideo"
        loop
        muted
        autoplay
      ></video>
      <!-- 占位 -->
      <div style="width:100%;height:168px"></div>
      <!-- user -->
      <div class="contBox_user flexRow">
        <div class="boxUserLeft flexCol">
          <span class="userLeftHead"> {{ $t("m.contOne1") }}</span>
          <p
            v-show="animTypeObj[0].type === 1 || animTypeObj[0].type ===2"
            :class="animTypeObj[0].type === 1 ?  'userLeftP userLeftHeadAniType' : 'userLeftP'"
          >{{ $t("m.contOne2") }}</p>
          <p
            v-show="animTypeObj[0].type === 0"
            class="userLeftP"
          >&nbsp;</p>
          <p
            v-show="animTypeObj[0].type === 0"
            class="userLeftP"
          >&nbsp;</p>
          <p
            v-show="animTypeObj[0].type === 0"
            class="userLeftP"
          >&nbsp;</p>
          <p
            v-show="animTypeObj[0].type === 1 || animTypeObj[0].type ===2"
            :class="animTypeObj[0].type === 1 ?  'userLeftP userLeftHeadAniType' : 'userLeftP'"
          >{{ $t("m.contOne3") }}</p>
          <p
            v-show="animTypeObj[0].type === 1 || animTypeObj[0].type ===2"
            :class="animTypeObj[0].type === 1 ?  'userLeftP userLeftHeadAniType' : 'userLeftP'"
          >{{ $t("m.contOne4") }}</p>
          <span
            :class="animTypeObj[0].type === 1 ?  'userLeftTit userLeftHeadAniType' : 'userLeftTit'"
            v-show="animTypeObj[0].type === 1 || animTypeObj[0].type ===2"
          >{{ $t("m.contOne5") }} <span style="color:rgb(197,173,139)">{{ $t("m.contOne6") }}</span> </span>
          <span
            class="userLeftTit"
            v-show="animTypeObj[0].type === 0"
          >&nbsp;<span style="color:rgb(197,173,139)">&nbsp;</span> </span>
          <div class="flexRow">
            <div class="userLeftImgBox">
              <a
                href="https://itunes.apple.com/us/app/id1619744034?ls=1&mt=8"
                target="_blank"
              >
                <div class="userLeftImg userLeftImg1"></div>
              </a>
            </div>
            <div
              class="userLeftImgBox"
              style="margin-left: 20px;"
            >
              <a
                href="https://www.depay.biz/download/DePay.apk"
                target="_blank"
              >
                <div class="userLeftImg userLeftImg2"></div>
              </a>
            </div>
          </div>
        </div>
        <div class="boxUserRig">
          <img
            src="../imgs/metapay_app_wallet.webp"
            class="userRIgImg"
            alt=""
          >
        </div>
      </div>
    </div>

    <!-- merchant -->
    <div
      id="merchant"
      class="contBox_merchant flexCol"
    >
      <span
        v-show="animTypeObj[1].type === 0"
        class="box_mercha_tit"
      >&nbsp;</span>
      <span
        v-show="animTypeObj[1].type === 1 || animTypeObj[1].type ===2"
        :class="animTypeObj[1].type === 1 ?  'box_mercha_tit userLeftHeadAniType' : 'box_mercha_tit'"
      >{{ $t("m.contMerch1") }}</span>
      <span class="box_mercha_paragraph">{{ $t("m.contMerch2") }}</span>
      <span style="height:13px;"></span>
      <span class="box_mercha_paragraph">{{ $t("m.contMerch3") }}<a href="mailto:support@depay.biz">{{ $t("m.contMerch4") }}</a></span>
      <div
        class="flexRow"
        style="margin-top:80px"
      >
        <img
          src="../imgs/contImg1.webp"
          class="contImg1"
          alt=""
        >
        <div class="flexCol boxMerchaContBox">
          <span class="box_mercha_cont1Tit">{{ $t("m.contMerch5") }}</span>
          <p>{{ $t("m.contMerch6") }}</p>
        </div>
      </div>
      <div
        class="flexRow"
        style="margin-top:80px"
      >
        <div class="flexCol boxMerchaContBoxCopy">
          <span class="box_mercha_cont1Tit">{{ $t("m.contMerch7") }}</span>
          <p>{{ $t("m.contMerch8") }}</p>
        </div>
        <img
          src="../imgs/contImg2.webp"
          class="contImg1"
          alt=""
        >
      </div>
      <div
        class="flexRow"
        style="margin-top:80px"
      >
        <img
          src="../imgs/contImg3.webp"
          class="contImg1"
          alt=""
        >
        <div class="flexCol boxMerchaContBox">
          <span class="box_mercha_cont1Tit">{{ $t("m.contMerch9") }}</span>
          <p>{{ $t("m.contMerch10") }}</p>
        </div>
      </div>

    </div>
    <!-- nodeOperator -->
    <div
      id="nodeOperator"
      class="flexCol contBox_opera"
    >
      <span
        v-show="animTypeObj[2].type === 0"
        class="contBox_opera_title"
      >&nbsp;</span>
      <span
        v-show="animTypeObj[2].type === 1 || animTypeObj[2].type ===2"
        :class="animTypeObj[2].type === 1 ?  'contBox_opera_title userLeftHeadAniType' : 'contBox_opera_title'"
      >{{ $t("m.contOpera1") }}</span>
      <p>{{ $t("m.contOpera2") }}</p>
      <div class="flexRow">
        <img
          src="../imgs/operaImg1.webp"
          alt=""
        >
        <img
          src="../imgs/operaImg2.webp"
          alt=""
          style="margin-left:30px;"
        >
      </div>

    </div>
    <!-- investor -->
    <div
      id="investor"
      class="flexCol contBox_investor"
    >
      <span
        style="margin-left: 20%;"
        v-show="animTypeObj[3].type === 0"
        class="inve_tit"
      >&nbsp;</span>
      <span
        style="margin-left: 20%;"
        v-show="animTypeObj[3].type === 1 || animTypeObj[3].type ===2"
        :class="animTypeObj[3].type === 1 ?  'inve_tit userLeftHeadAniType' : 'inve_tit'"
      >{{ $t("m.contInves1") }}</span>

      <p>{{ $t("m.contInves2") }}</p>
      <p style="height:21px"></p>
      <p>{{ $t("m.contInves3") }}</p>
      <p style="height:21px"></p>
      <span
        style="margin-left: 20%;"
        class=""
      >{{ $t("m.contInves4") }} <a href="mailto:support@depay.biz">{{ $t("m.contInves5") }}</a></span>
      <img
        src="../imgs/Meeting.webp"
        class="contBox_inversImg"
        alt=""
      >
    </div>
    <!-- aboutUs -->
    <div
      id="aboutUs"
      class="flexCol contBox_about"
    >
      <span
        v-show="animTypeObj[4].type === 0"
        class="cont_about_tit"
      >&nbsp;</span>
      <span
        v-show="animTypeObj[4].type === 1 || animTypeObj[4].type ===2"
        :class="animTypeObj[4].type === 1 ?  'cont_about_tit userLeftHeadAniType' : 'cont_about_tit'"
      >{{ $t("m.contAbout1") }}</span>

      <p>{{ $t("m.contAbout2") }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  data() {
    return {
      animTypeObj: [
        {
          type: 0,
        },
        {
          type: 0,
        },
        {
          type: 0,
        },
        {
          type: 0,
        },
        {
          type: 0,
        }
      ]
    }
  },
  props: {
    headIndexdefAnm: {
      type: Number,
      default: 0
    }
  },
  watch: {
    headIndexdefAnm: {
      handler(newVal, oldVal) {
        console.log(newVal, oldVal)
        if (this.animTypeObj[newVal].type === 0) {
          this.animTypeObj[newVal].type = 1
          setTimeout(() => {
            this.animTypeObj[newVal].type = 2
          }, 2000)
        }
        this.headIndex = newVal
      }
    }
  },
  methods: {
    goScrollView(item) {
      document.getElementById(item).scrollIntoView({ behavior: 'smooth' })
    }
  },
}
</script>

<style scoped lang='less'>
.userLeftHeadAniType {
  -webkit-animation-name: scaleDraw; /*关键帧名称*/
  -webkit-animation-timing-function: ease-in-out; /*动画的速度曲线*/
  -webkit-animation-iteration-count: infinite; /*动画播放的次数*/
  -webkit-animation-duration: 2s; /*动画所花费的时间*/
}
@keyframes scaleDraw {
  /*定义关键帧、scaleDrew是需要绑定到选择器的关键帧名称*/

  0% {
    opacity: 0;
  }

  25% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.5;
  }

  75% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
}
.flexRow {
  display: flex;
  flex-direction: row;
}
.flexCol {
  display: flex;
  flex-direction: column;
}
.contBoxHeadBack {
  .posVideo {
    min-width: 100%;
    min-height: 100%;
    height: auto;
    width: auto;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -11;
    /*加滤镜*/
    // filter: blur(15px); //背景模糊设置
    // -webkit-filter: grayscale(100%);
    // filter: grayscale(100%); //背景灰度设置
  }
}
.contBox {
  width: 100%;
  overflow: hidden;
  .boxUserLeft {
    width: 50%;
    align-items: flex-start;
    padding-left: 20%;
    box-sizing: border-box;
    font-weight: bold;
    .userLeftHead {
      font-size: 90px;
      color: rgb(65, 65, 65);
    }
    .userLeftP {
      font-size: 15px;
      color: rgb(65, 65, 65);
      letter-spacing: 0;
      margin: 0;
    }
    .userLeftTit {
      margin-top: 148px;
      font-size: 50px;
      color: rgb(65, 65, 65);
    }
    .userLeftImgBox {
      cursor: pointer;
      width: 156px;
      margin-top: 20px;
    }
    // .userLeftImgBox:hover {
    //   padding: 0 48px;
    //   box-sizing: border-box;
    //   .userLeftImg {
    //     background-image: url(../imgs/iconEye.webp);
    //     background-size: 100% 100%;
    //   }
    // }
    .userLeftImgBox:hover {
      animation: shake 800ms ease-in-out;
    }

    @keyframes shake {
      /* 水平抖动，核心代码 */
      10%,
      90% {
        transform: translate3d(-1px, 0, 0);
      }
      20%,
      80% {
        transform: translate3d(+2px, 0, 0);
      }
      30%,
      70% {
        transform: translate3d(-4px, 0, 0);
      }
      40%,
      60% {
        transform: translate3d(+4px, 0, 0);
      }
      50% {
        transform: translate3d(-4px, 0, 0);
      }
    }
    .userLeftImg {
      width: 100%;
      height: 60px;
    }
    .userLeftImg1 {
      background-image: url(../imgs/appleStoreImg.webp);
      background-size: 100% 100%;
    }
    .userLeftImg2 {
      background-image: url(../imgs/googleImg.webp);
      background-size: 100% 100%;
    }
  }
  .boxUserRig {
    width: 50%;
    // margin-bottom: 90px;
    padding-bottom: 90px;
    .userRIgImg {
      width: 296px;
      height: 591px;
    }
  }
}
.contBox_user {
  width: 100%;
}

.contBox_merchant {
  background: rgb(255, 255, 255);
  font-weight: bold;
  padding-left: 20%;
  width: 100%;
  box-sizing: border-box;
  padding-bottom: 176px;
  .box_mercha_tit {
    margin: 87px 0 32px;
    font-size: 90px;
    text-align: left;
  }
  .box_mercha_paragraph {
    font-size: 17px;
    width: 800px;
    text-align: left;
    a {
      cursor: pointer;
      text-decoration: none;
      color: inherit;
    }
  }
  .contImg1 {
    width: 408px;
    height: 400px;
  }
  .boxMerchaContBox {
    margin-left: 90px;
    width: 400px;
    justify-content: center;
    .box_mercha_cont1Tit {
      font-size: 36px;
      color: rgb(148, 130, 104);
      text-align: left;
    }
    p {
      text-align: left;
      font-size: 15px;
      color: rgb(65, 65, 65);
      font-weight: normal;
    }
  }
  .boxMerchaContBoxCopy {
    margin-right: 90px;
    width: 400px;
    justify-content: center;
    .box_mercha_cont1Tit {
      font-size: 36px;
      color: rgb(148, 130, 104);
      text-align: left;
    }
    p {
      text-align: left;
      font-size: 15px;
      color: rgb(65, 65, 65);
      font-weight: normal;
    }
  }
}
.contBox_opera {
  background-color: rgb(226, 212, 193);
  height: 824px;
  padding-left: 20%;
  width: 100%;
  font-weight: bold;
  text-align: left;
  .contBox_opera_title {
    font-size: 90px;
    margin: 97px 0 32px;
  }
  p {
    font-size: 17px;
    width: 780px;
    margin-bottom: 75px;
  }
  img {
    width: 433px;
    height: 289px;
  }
}
.contBox_investor {
  background-color: rgb(250, 250, 250);
  height: 910px;
  padding-left: 20%;
  width: 100%;
  font-weight: bold;
  text-align: left;
  .inve_tit {
    letter-spacing: normal;
    font-size: 90px;
    margin: 96px 0 67px;
  }
  p {
    width: 760px;
    font-size: 15px;
    font-weight: normal;
    margin: 0;
  }
  a {
    cursor: pointer;
    text-decoration: none;
    color: inherit;
  }
  .contBox_inversImg {
    width: 755px;
    height: 358px;
    margin-top: 59px;
  }
}
.contBox_about {
  width: 100%;
  font-weight: bold;
  align-items: center;
  .cont_about_tit {
    text-align: center;
    font-size: 90px;
    margin-bottom: 50px;
    margin-top: 110px;
  }
  p {
    width: 745px;
    text-align: center;
    margin-bottom: 130px;
  }
}
</style>
