<template>
  <div class="home_head_box">
    <el-row :gutter="20">
      <el-col :span="4">
        <div class="svgList">
          <div class="svgBox"></div>
          <div
            class="titHead"
            style="font-size: 20px;"
          >DePay
          </div>
        </div>
      </el-col>
      <el-col
        :span="14"
        :offset="4"
      >
        <div class="anchorList">
          <span
            :class="headIndex === 0 ? 'span_anchor span_anchor_trans' : 'span_anchor'"
            @click="anchorFun('user',0)"
          >
            {{ $t("m.headTit1") }}
          </span>
          <span
            :class="headIndex === 1 ? 'span_anchor span_anchor_trans' : 'span_anchor'"
            @click="anchorFun('merchant',1)"
          >
            {{ $t("m.headTit2") }}
          </span>
          <span
            :class="headIndex === 2 ? 'span_anchor span_anchor_trans' : 'span_anchor'"
            @click="anchorFun('nodeOperator',2)"
          >
            {{ $t("m.headTit3") }}
          </span>
          <span
            :class="headIndex === 3 ? 'span_anchor span_anchor_trans' : 'span_anchor'"
            @click="anchorFun('investor',3)"
          >
            {{ $t("m.headTit4") }}
          </span>
          <span
            :class="headIndex === 4 ? 'span_anchor span_anchor_trans' : 'span_anchor'"
            @click="anchorFun('aboutUs',4)"
          >
            {{ $t("m.headTit5") }}
          </span>
          <el-select
            v-model="seleValue"
            placeholder="请选择"
            @change="cutLang"
            style="width:85px;margin-left:20px"
          >
            <el-option
              v-for="item in seleOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: '',
  data() {
    return {
      headList: [
        {
          name: 'User',
          value: 0
        },
        {
          name: 'Merchant',
          value: 0
        },
        {
          name: 'Node Operator',
          value: 0
        },
        {
          name: 'Investor',
          value: 0
        },
        {
          name: 'About Us',
          value: 0
        },
      ],
      headIndex: 0,
      seleOptions: [{
        value: 'EN',
        label: 'EN'
      }, {
        value: 'ZH',
        label: 'ZH'
      }],
      seleValue: 'EN'
    }
  },
  created() {

  },
  mounted() {

  },
  props: {
    headIndexdef: {
      type: Number,
    }
  },
  watch: {
    headIndexdef: {
      // deep: true,  // 深度监听
      handler(newVal, oldVal) {
        console.log(newVal, oldVal)
        this.headIndex = newVal
      }
    }
  },
  methods: {
    anchorFun(item, i) {
      this.headIndex = i
      this.$emit('goView', item)
    },
    cutLang() {
      if (this.seleValue === 'EN') {
        this.$i18n.locale = "en-US";
      } else if (this.seleValue === 'ZH') {
        this.$i18n.locale = "zh-CN";
      }
    }
  },
}
</script>

<style scoped lang='less'>
.home_head_box {
  margin: 53px 0 55px;
}
.svgList {
  display: flex;
  flex-direction: row;
  height: 60px;
  align-items: center;
  justify-content: center;
  .titHead {
    color: rgb(65, 65, 65);
    font-size: 20px !important;
    font-weight: bold;
    margin-left: 20px;
  }
}
.svgBox {
  width: 38px;
  height: 30px;
  background-image: url('../imgs/logo.svg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.anchorList {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  .span_anchor {
    // width: 139px;
    height: 60px;
    padding: 0 20px;
    box-sizing: border-box;
    visibility: inherit;
    color: rgb(var(--txt, var(--color_15)));
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .span_anchor_trans {
    background-color: rgb(237, 233, 225);
    transition: background-color 1s;
  }
}
</style>
